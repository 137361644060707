import { Container, Col, Row } from "react-bootstrap";
import "./App.css";
import Home from "./Components/Home/Home";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import SecondNavBar from "./Components/NavigationBar/SecondNavBar/SecondNavBar";
import TopNavBar from "./Components/NavgBar/TopNavBar";
import {
	BrowserRouter,
	Routes,
	Link,
	Route,
	Navigate,
	NavLink,
} from "react-router-dom";
import Aboutus from "./Components/Aboutus/Aboutus";
import Activities from "./Components/Activities/Activities";
import Resort from "./Components/Resort/Resort";
import Accomadation from "./Components/Accomadation/Accomadation";
import EnvironmentPolicies from "./Components/EnvironmentPolicies/EnvironmentPolicies";
import Ayurweda from "./Components/Ayurweda/Ayurweda";
import Reservations from "./Components/Reservations/Reservations";
import WhatsNew from "./Components/WhatsNew/WhatsNew";
import Weddings from "./Components/Weddings/Weddings";

import Gallery from "./Components/Gallery/Gallery";
import Contactus from "./Components/Contactus/Contactus";
import Partners from "./Components/Partners/Partners";
import Offers from "./Components/Offers/Offers";
import Awards from "./Components/Awards/Awards";
import Videos from "./Components/Videos/Videos";
// import Pagination from "./Components/WhatsNew/Pagination";
import BritishSchool from "./Components/WhatsNew/BritishSchool";
// import AsianGreening from "./Components/WhatsNew/AsianGreening";
import KualaLumpurVisited from "./Components/WhatsNew/KualaLumpurVisited";
import Blog from "./Components/Blog/Blog";
import EchoLodge from "./Components/WhatsNew/EchoLodge";
import Page from "./Components/Page/Page";

function App() {
	return (
		<div>
			<Container>
				<section>
					<div className="body-container">
						<TopNavBar />
						<SecondNavBar />
						<BrowserRouter>
							<Routes>
								{/* <Route exact path='/page/' element={<Home />} /> */}
								<Route path="/" element={<Navigate replace to="/page/" />} />
								<Route path="/page/" element={<Home />} />
								<Route path="/page/*" element={<Page />} />
								<Route
									path="/page/contact"
									element={<Navigate replace to="/contactus" />}
								/>
								<Route path="/contactus" element={<Contactus />} />
								<Route
									path="/page/reservation"
									element={<Navigate replace to="/reservations" />}
								/>
								<Route path="/reservations" element={<Reservations />} />
								<Route
									path="/page/whatsnew"
									element={<Navigate replace to="/whatsnew" />}
								/>
								{/* <Route path="/whatsnew" element={<WhatsNew />} /> */}
								<Route path="/whatsnew" element={<WhatsNew />} />
							</Routes>
						</BrowserRouter>
					</div>
				</section>
			</Container>
		</div>
	);
}

export default App;
