import React, { useRef } from "react";
import { useState } from "react";
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import "./Contactus.css";
import ReCAPTCHA from "react-google-recaptcha";
import bird from "../../assests/Images/kingfish.png";
import contact_us from "../../assests/Images/contact_us.jpeg";
import Footer from "../Footer/Footer";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { FaFax } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import Axios from "axios";
// import emailjs from 'emailjs-com';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Contactus() {
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const cForm = useRef();

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};

	const findFormErrors = () => {
		const { user_name, email, cidate, message, rooms, child } = form;
		const newErrors = {};

		// NAME ERROS
		if (!user_name || user_name.trim() == "") {
			newErrors.user_name = "Name is Required";
		}
		///email
		if (!email || email.trim() == "") {
			newErrors.email = "Email is Required";
		}

		const regex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email || email !== "") {
			if (regex.test(email) === false) {
				newErrors.email = "Please enter a valid email address";
			}
		}

		//date
		// if (!cidate || cidate.trim() == '') {
		// 	newErrors.cidate = 'Subject is Required';
		// }

		if (!message || message.trim() == "") {
			newErrors.message = "Message is Required";
		}

		// if (!rooms || rooms.trim() == '') {
		// 	newErrors.rooms = 'Rooms is Required';
		// }
		// if (!child || child.trim() == '') {
		// 	newErrors.child = 'Rooms is Required';
		// }

		return newErrors;
	};

	// const notify=()=>{
	
	// }

	const handleSubmit = async (e) => {
		debugger;
		e.preventDefault();
		//get our new errors
		const newErrors = findFormErrors();
		//Conditional Login
		if (Object.keys(newErrors).length > 0) {
			//WE GOT ERRORS
			setErrors(newErrors);
		} else {
			// Axios.post(
			// 	`https://us-central1-ranweli-app.cloudfunctions.net/sendMailOverHTTP`,
			// 	form
			// );
			sendEmail();
		}
	};

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_852ok3m",
				"template_un4cmcl",
				form.current,
				"MSEkkN8BGkxS-U9e0"
			)
			.then(
				(result) => {
					toast.success("Your Message Sent!");
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	return (
		<div>
			<Container>
				<Row className="ch">
					<img width="100%vw" style={{ padding: "0px" }} src={contact_us} />

					<div className="col-md-8">
						<div className="uper-body">
							<h2 className="home-welcome">Contact Us</h2>
							<ToastContainer/>
							<div className="welcome-body">
								<p>
									Have a suggestion on how to improve our service? Feedback
									about the site? Please use the form below to send us your
									thoughts, suggestions and questions.
								</p>

								<form ref={form} onSubmit={sendEmail}>
									<label>
										<b>Name</b> <span className="astric">*</span>
									</label>
									<br></br>
									<br></br>
									<input type="text" className="input" name="user_name" required/>
									<br></br>
									<br></br>
									<label>
										<b>Email</b> <span className="astric">*</span>
										<br></br>
									<br></br>
									</label>
									<br></br>
									<input type="email" className="input" name="user_email" required/>
									<br></br>
									<br></br>
									<label>
										<b>Subject</b> <span className="astric">*</span>
										<br></br>
									<br></br>
									</label>
									<br></br>
									<input type="text" className="input" name="user_email" required/>
									<br></br>
									<br></br>

									<label>
										<b>Message</b> <span className="astric">*</span><br></br>
										
									<br></br>
									</label>
									
									<textarea className="input-height" name="message" required/>
									<br></br>
									<input
										className="contact-submit1"
										type="submit"
										value="Send"
										// onClick={notify}
									/>
									
									<button variant="primary" type="reset" className="contact-submit2">
									Reset
								</button>
								</form>
								<br></br>
								<br></br>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<br></br>
						<br></br>
						<br></br>
						<div className="col-md-12">
							<p className="title4">Phone:</p>
							<p className="title4-body">
								<BsFillTelephoneOutboundFill /> (94) 31 2277359
							</p>
							<p className="title4-body">
								<BsFillTelephoneOutboundFill /> (94) 31 2276390
							</p>
							<p className="title4-body">
								<BsFillTelephoneOutboundFill /> (94) 31 2276069
							</p>
							<p className="title4-body">
								<BsFillTelephoneOutboundFill /> (94) 771096739
							</p>
						</div>
						<p className="title4">Email:</p>
						<a href="/sss" className="home-linkac">
							<AiTwotoneMail /> ranwelires@sltnet.lk
						</a>

						<p className="title4">Fax:</p>
						<p className="title4-body">
							<FaFax /> (94) 31 2277358
						</p>

						<p className="title4">Address:</p>
						<p className="title4-body">
							<FaAddressCard /> Ranweli Holiday Village, Waikkal, Sri Lanka.
						</p>
					</div>
				</Row>
			</Container>

			{/* <form ref={form} onSubmit={sendEmail}>
				<label>Name</label>
				<input type="text" name="user_name" />
				<label>Email</label>
				<input type="email" name="user_email" />
				<label>Message</label>
				<textarea name="message" />
				<input type="submit" value="Send" />
			</form> */}

			<Footer />
		</div>
	);
}
