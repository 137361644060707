import React, { useEffect, useState } from "react";
import { projectFirestore } from "../../Firebase/Config";
import { Col, Row, Container, Button } from "react-bootstrap";
import Footer from "../Footer/Footer";
import wedding from "../../assests/Images/wedding.jpeg";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

function Page() {
	const [data, setData] = useState(null);
	const [moduleData, setModuleData] = useState(null);
	const blogTable = projectFirestore.collection("blog");
	const moduleTable = projectFirestore.collection("module");

	useEffect(async () => {
		var myParam = window.location.pathname;
		const words = myParam.split("/");
		const searchWord = words[2];
		fetchData(searchWord);
		fetchModuleData(searchWord);
	}, []);

	//Pagination Function Start
	async function fetchData(searchWord) {
		const full = blogTable.orderBy("createdAt", "desc").limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		var tempBlogs = [];

		for (var i = 0; i < fullsnapshot.docs.length; i++) {
			var doc = fullsnapshot.docs[i];
			var item = await doc.data();

			if (item.blog_slug == `/${searchWord}`) {
				tempBlogs.push({
					id: doc.id,
					blog_description: item.blog_description,
					blog_slug: item.blog_slug,
					blog_name: item.blog_name,
					blog_image: item.blog_image,
					createdAt: item.createdAt,
					status: item.status,
				});
			}
		}
		setData(tempBlogs);
	}

	async function fetchModuleData(searchWord) {
		debugger;
		const full = moduleTable.orderBy("createdAt", "desc").limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		var tempModules = [];

		for (var i = 0; i < fullsnapshot.docs.length; i++) {
			var doc = fullsnapshot.docs[i];
			var item = await doc.data();

			if (item.module_page == `/${searchWord}`) {
				tempModules.push({
					id: doc.id,
					module_description: item.module_description,
					module_page: item.module_page,
					module_title: item.module_title,
					createdAt: item.createdAt,
					status: item.status,
				});
			}
		}
		setModuleData(tempModules);
	}
	//Pagination Function End

	const parser = (input) =>
		parse(input, {
			replace: (domNode) => {
				if (domNode instanceof Element && domNode.attribs.class === "remove") {
					return <></>;
				}
			},
		});

	return (
		<div>
			<Container>
				<Row className="wdh">
					{data &&
						data.map((article, index) => (
							<img
								width="100%vw"
								style={{ padding: "0px" }}
								src={article.blog_image}
							/>
						))}

					{data &&
						data.map((article, index) => (
							<div className="col-md-8">
								<div className="uper-body" key={index}>
									<h2 className="home-welcome">{article.blog_name}</h2>
									<div className="welcome-body">
										{parser(`${article.blog_description}`)}
									</div>
								</div>
							</div>
						))}

					{moduleData &&
						moduleData.map((module, index) => (
							<div className="col-md-4">
								{parser(`${module.module_description}`)}
							</div>
						))}
					<br></br>
					<br></br>
				</Row>
			</Container>
			<Footer />
		</div>
	);
}

export default Page;
