import React, { useState, useMemo, useEffect } from "react";
import { Col, Row, Container, Button, Pagination } from "react-bootstrap";
import "./WhatsNew.css";
// import Pagination from "./Pagination.js";
import pdfIcon from "../../assests/Images/pdfIcon.jpeg";
import inner_banner06 from "../../assests/Images/inner_banner06.jpeg";
import Footer from "../Footer/Footer";
// import data from "./data/data.json";
// import "./Pagination.js";
import { projectFirestore } from "../../Firebase/Config";

let PageSize = 4;

export default function WhatsNew() {
	const [currentPage, setCurrentPage] = useState(1);
	// const currentTableData = useMemo(() => {
	// 	const firstPageIndex = (currentPage - 1) * PageSize;
	// 	const lastPageIndex = firstPageIndex + PageSize;
	// 	return data.slice(firstPageIndex, lastPageIndex);
	// }, [currentPage]);
	const newsItemsTable = projectFirestore.collection("newsItems");
	const [pageSize, setPageSize] = useState(10);
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pageArray, setPageArray] = useState([]);

	useEffect(async () => {
		fetchData(currentPage);
	}, []);

	//Pagination Function Start
	async function fetchData(page) {
		setIsLoading(true);
		var currentObj = "";
		const full = newsItemsTable.orderBy("createdAt", "desc").limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		// setRows(fullsnapshot.docs.length); // ROW COUNT

		if (page - 1 > 0) {
			var nextData = fullsnapshot.docs[(page - 1) * pageSize - 1];
			currentObj = newsItemsTable
				.orderBy("createdAt", "asc")
				.startAfter(nextData.data().createdAt)
				.limit(pageSize);
		} else {
			currentObj = newsItemsTable.orderBy("createdAt", "desc").limit(pageSize);
		}

		const snapshot = await currentObj.get();
		// setNextData(snapshot.docs[snapshot.docs.length - 1]); // NEXT DATA

		var tempBlogs = [];

		for (var i = 0; i < snapshot.docs.length; i++) {
			var doc = snapshot.docs[i];
			var item = await doc.data();

			tempBlogs.push({
				id: doc.id,
				news_header: item.news_header,
				news_description: item.news_description,
				news_image: item.news_image,
				createdAt: item.createdAt,
				status: item.status,
				news_url: item.news_url,
			});
		}
		setIsLoading(false);
		setData(tempBlogs);

		var temppageArray = [];
		var toPage = 0;
		var frompage = currentPage;
		var pageCount =
			fullsnapshot.docs.length > 0
				? Math.ceil(fullsnapshot.docs.length / pageSize)
				: 0; // 16

		// 0 123 -4 Page- 5 6 7 8 9 10 11 12 13 14 15 16 ( rows 160 , Pages 16)

		if (currentPage + 10 < pageCount) {
			toPage = currentPage + 10; // 14 Pages
		} else {
			toPage = pageCount; // 16
		}

		if (currentPage - 10 <= 0) {
			frompage = 1;
		} else {
			frompage = currentPage - 10;
		}

		for (var i = frompage; i <= toPage; i++) {
			temppageArray.push(i);
		}
		setPageArray(temppageArray);
	}
	//Pagination Function End

	// Pagniation on button click
	const handleClick = async (e) => {
		setData([]);
		setCurrentPage(e.target.id);

		fetchData(e.target.id);
	};

	// Next Button
	const handleNextBtn = () => {
		setData([]);
		setCurrentPage(currentPage + 1);
		fetchData(currentPage + 1);
	};

	// Previous Button
	const handlePreviousBtn = () => {
		setData([]);
		setCurrentPage(currentPage - 1);
		fetchData(currentPage - 1);
	};

	return (
		<div>
			<Container>
				<Row className=" wh">
					<img width="100%vw" style={{ padding: "0px" }} src={inner_banner06} />

					<div className="col-md-8">
						<div className="uper-body">
							<h2 className="home-welcome">What's New</h2>

							<div className="welcome-body">
								{data &&
									data.map((item) => {
										return (
											<p className="title2">
												<b>{item.news_header}</b>
												<Row>
													<div className="col-md-6">
														<br></br>
														<img
															width="100%vw"
															style={{ padding: "0px" }}
															className="timthumb"
															src={item.news_image}
														/>
													</div>

													<div className="col-md-6">
														<p className="activity-body">
															{item.news_description}
														</p>
														<Button
															variant="primary"
															className="submit1"
															type="submit"
															href={item.news_url}
														>
															Read More
														</Button>
													</div>
												</Row>
											</p>
										);
									})}
								<br></br>
								<>
									
									<Pagination style={{ float: "right" ,listStyleType:'none'}}>
										<Pagination.Prev
											onClick={handlePreviousBtn}
											className={
												currentPage === pageArray[0] ? "disabled" : "    "
											}
										/>
										{pageArray.map((page) => (
											<Pagination.Item
												key={page}
												id={page}
												onClick={handleClick}
												className={currentPage === page && "active"}
											>
												{page}
											</Pagination.Item>
										))}
										<Pagination.Next
											onClick={handleNextBtn}
											className={
												currentPage === pageArray.length ? "disabled" : ""
											}
										/>
									</Pagination>
								</>
							</div>
						</div>
						<br></br>
						<br></br>
					</div>

					<div className="col-md-4">
						<Row>
							<div className="col-md-12 whts-tab">
								<table>
									<tr>
										<th style={{color:"#fff"}}>Write Up</th>
										<th></th>
									</tr>
									<tr >
										<a style={{ textDecoration: "none" }} href="https://web.archive.org/web/20190612134604/http://ranweli.com/images/write_up/honeymooh_in_paradise.pdf">
											<td > Honeymoon in paradise</td>
										</a>

										<td>
											<img src={pdfIcon} />
										</td>
									</tr>
									<tr>
										<a
											style={{ textDecoration: "none" }}
											href="https://web.archive.org/web/20190612111053/http://ranweli.com/images/write_up/bird_friendly_eco_resort.pdf"
										>
											<td>Bird friendly eco resort</td>
										</a>
										<td>
											<img src={pdfIcon} />
										</td>
									</tr>
									<tr>
										<a
											style={{ textDecoration: "none" }}
											href="https://web.archive.org/web/20190612080933/http://ranweli.com/images/write_up/ranweli_among_top_10_eco-destinations.pdf"
										>
											<td>Ranweli among top 10 eco-destinations</td>
										</a>
										<td>
											<img src={pdfIcon} />
										</td>
									</tr>
									<tr>
										<a
											style={{ textDecoration: "none" }}
											href="https://web.archive.org/web/20190612150301/http://ranweli.com/images/write_up/Ranweli_golden_lure.pdf"
										>
											<td>Ranweli's golden lure</td>
										</a>
										<td>
											<img src={pdfIcon} />
										</td>
									</tr>
								</table>
							</div>
						</Row>
					</div>

					<br></br>
					<br></br>
				</Row>
			</Container>
			<Footer />
		</div>
	);
}
