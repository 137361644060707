import React, { useEffect, useState, useRef } from "react";
import "./TopNavBar.css";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../assests/Images/Ranweli_Logo.png";
import ranweli_fb_icon from "../../assests/Images/ranweli_fb_icon.png";
import { projectFirestore } from "../../Firebase/Config";
import ranweli_flickr_icon from "../../assests/Images/ranweli_flickr_icon.png";

export default function TopNavBar() {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pageArray, setPageArray] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const blogTable = projectFirestore.collection("blog");
	const menuTable = projectFirestore.collection("menu");
	const [pageSize, setPageSize] = useState(10);

	useEffect(async () => {
		fetchData(currentPage);
		// fetchDataSub(currentPage);
	}, []);

	//Pagination Function Start
	async function fetchData(page) {
		setIsLoading(true);
		var currentObj = "";
		const full = menuTable.orderBy("createdAt", "desc").limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		// setRows(fullsnapshot.docs.length); // ROW COUNT

		if (page - 1 > 0) {
			var nextData = fullsnapshot.docs[(page - 1) * pageSize - 1];
			currentObj = menuTable
				.orderBy("createdAt", "asc")
				.startAfter(nextData.data().createdAt)
				.limit(pageSize);
		} else {
			currentObj = menuTable.orderBy("createdAt", "desc").limit(pageSize);
		}

		const snapshot = await currentObj.get();
		// setNextData(snapshot.docs[snapshot.docs.length - 1]); // NEXT DATA

		var tempBlogs = [];

		for (var i = 0; i < snapshot.docs.length; i++) {
			var doc = snapshot.docs[i];
			var item = await doc.data();

			item.offers.sort(function (a, b) {
				return a.order - b.order;
			});

			tempBlogs.push({
				id: doc.id,
				menu_name: item.menu_name,
				menu_url: item.menu_url,
				menu_order: item.menu_order,
				createdAt: item.createdAt,
				status: item.status,
				menu_offers: item.offers,
			});
		}
		setIsLoading(false);
		setData(tempBlogs);

		var temppageArray = [];
		var toPage = 0;
		var frompage = currentPage;
		var pageCount =
			fullsnapshot.docs.length > 0
				? Math.ceil(fullsnapshot.docs.length / pageSize)
				: 0; // 16

		// 0 123 -4 Page- 5 6 7 8 9 10 11 12 13 14 15 16 ( rows 160 , Pages 16)

		if (currentPage + 10 < pageCount) {
			toPage = currentPage + 10; // 14 Pages
		} else {
			toPage = pageCount; // 16
		}

		if (currentPage - 10 <= 0) {
			frompage = 1;
		} else {
			frompage = currentPage - 10;
		}

		for (var i = frompage; i <= toPage; i++) {
			temppageArray.push(i);
		}
		setPageArray(temppageArray);
	}
	//Pagination Function End

	// async function fetchDataSub(page) {
	// 	setIsLoading(true);
	// 	var currentObj = '';
	// 	const full = menuTable.orderBy('createdAt', 'desc').limit(50);
	// 	const fullsnapshot = await full.get(); // ALL USERS

	// 	// setRows(fullsnapshot.docs.length); // ROW COUNT

	// 	if (page - 1 > 0) {
	// 		var nextData = fullsnapshot.docs[(page - 1) * pageSize - 1];
	// 		currentObj = menuTable
	// 			.orderBy('createdAt', 'asc')
	// 			.startAfter(nextData.data().createdAt)
	// 			.limit(pageSize);
	// 	} else {
	// 		currentObj = menuTable.orderBy('createdAt', 'desc').limit(pageSize);
	// 	}

	// 	const snapshot = await currentObj.get();
	// 	// setNextData(snapshot.docs[snapshot.docs.length - 1]); // NEXT DATA

	// 	var tempBlogs = [];

	// 	for (var i = 0; i < snapshot.docs.length; i++) {
	// 		var doc = snapshot.docs[i];
	// 		var item = await doc.data();

	// 		item.offers.sort(function (a, b) {
	// 			return a.order - b.order;
	// 		});

	// 		tempBlogs.push({
	// 			id: doc.id,
	// 			menu_name: item.menu_name,
	// 			menu_url: item.menu_url,
	// 			menu_order: item.menu_order,
	// 			createdAt: item.createdAt,
	// 			status: item.status,
	// 			menu_offers: item.offers,
	// 		});
	// 	}
	// 	setIsLoading(false);
	// 	setData(tempBlogs);

	// 	var temppageArray = [];
	// 	var toPage = 0;
	// 	var frompage = currentPage;
	// 	var pageCount =
	// 		fullsnapshot.docs.length > 0
	// 			? Math.ceil(fullsnapshot.docs.length / pageSize)
	// 			: 0; // 16

	// 	// 0 123 -4 Page- 5 6 7 8 9 10 11 12 13 14 15 16 ( rows 160 , Pages 16)

	// 	if (currentPage + 10 < pageCount) {
	// 		toPage = currentPage + 10; // 14 Pages
	// 	} else {
	// 		toPage = pageCount; // 16
	// 	}

	// 	if (currentPage - 10 <= 0) {
	// 		frompage = 1;
	// 	} else {
	// 		frompage = currentPage - 10;
	// 	}

	// 	for (var i = frompage; i <= toPage; i++) {
	// 		temppageArray.push(i);
	// 	}
	// 	setPageArray(temppageArray);
	// }
	// //Pagination Function End

	return (
		<div>
			<div className="navigation-bar">
				<div className="extra-links">
					<a href="https://www.facebook.com/Ranweli.Holiday.Village/">
						<img className="img-fluid me-3" src={ranweli_fb_icon} />
					</a>
					<a href="https://www.flickr.com/search/?q=ranweli">
						<img className="img-fluid" src={ranweli_flickr_icon} />
					</a>
				</div>

				<Navbar
					collapseOnSelect
					className="top-nav"
					expand="lg"
					bg=""
					variant="dark"
				>
					<Container>
						<Navbar.Brand href="/">
							<img src={logo} className="nav-logo" />
						</Navbar.Brand>

						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav id="container">
								<div className="mobile-menu ">
									{/* <Nav.Link href='/'>Home</Nav.Link>
									<Nav.Link href='/activity'>Activity</Nav.Link>
									<Nav.Link href='/resort'>The Resort</Nav.Link>
									<Nav.Link href='/accomadation'>Accomadation</Nav.Link>
									<Nav.Link href='/environment'>Environment Policies</Nav.Link>
									<Nav.Link href='/ayurweda'>Ayurveda</Nav.Link>
									<Nav.Link href='/wedding' className='secondnavlink-menu'>
										Weddings
									</Nav.Link>
									<Nav.Link href='/reservation' className='secondnavlink-menu'>
										Reservations
									</Nav.Link> */}
									{data &&
										data.map(
											(menu, index) =>
												menu.menu_order == 1 &&
												menu.menu_offers &&
												menu.menu_offers.map((item, index) => (
													<Nav.Link
														key={index}
														className="mobile-menu "
														href={`${item.url}`}
													>
														{item.lable}
													</Nav.Link>
												))
										)}
								</div>
								{/* <Nav.Link href='/about'>About Us</Nav.Link>
								<Nav.Link href='/parnters'>Our Partners</Nav.Link>
								<Nav.Link href='/award'>Awards & Recognition</Nav.Link>
								<Nav.Link href='http://ranweliholidayvillage.blogspot.com/'>
									Blog
								</Nav.Link>
								<Nav.Link href='/whatsnew'>What's New</Nav.Link>
								<Nav.Link href='/gallery'>Gallery</Nav.Link>
								<Nav.Link href='/offers'>Special Offers</Nav.Link>
								<Nav.Link href='/contact'>Contact us</Nav.Link> */}
								{data &&
									data.map(
										(menu, index) =>
											menu.menu_order == 2 &&
											menu.menu_offers &&
											menu.menu_offers.map((item, index) => (
												<Nav.Link
													key={index}
													className="secondnavlink-menu"
													href={`${item.url}`}
												>
													{item.lable}
												</Nav.Link>
											))
									)}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		</div>
	);
}
