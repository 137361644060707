import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./SecondNavBar.css";
import { NavLink } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { projectFirestore } from "../../../Firebase/Config";

export default function SecondNavBar() {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pageArray, setPageArray] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const blogTable = projectFirestore.collection("blog");
	const menuTable = projectFirestore.collection("menu");
	const [pageSize, setPageSize] = useState(10);

	useEffect(async () => {
		fetchData(currentPage);
	}, []);

	//Pagination Function Start
	async function fetchData(page) {
		debugger;
		setIsLoading(true);
		var currentObj = "";
		const full = menuTable.orderBy("menu_order", "asc").limit(50);
		const fullsnapshot = await full.get(); // ALL USERS

		// setRows(fullsnapshot.docs.length); // ROW COUNT

		if (page - 1 > 0) {
			var nextData = fullsnapshot.docs[(page - 1) * pageSize - 1];
			currentObj = menuTable
				.orderByChild("menu_order", "asc")
				.startAfter(nextData.data().createdAt)
				.limit(pageSize);
		} else {
			currentObj = menuTable.orderBy("menu_order", "asc").limit(pageSize);
		}

		const snapshot = await currentObj.get();
		// setNextData(snapshot.docs[snapshot.docs.length - 1]); // NEXT DATA

		var tempBlogs = [];

		for (var i = 0; i < snapshot.docs.length; i++) {
			var doc = snapshot.docs[i];
			var item = await doc.data();

			item.offers.sort(function (a, b) {
				return a.order - b.order;
			});

			tempBlogs.push({
				id: doc.id,
				menu_name: item.menu_name,
				menu_url: item.menu_url,
				menu_order: item.menu_order,
				createdAt: item.createdAt,
				status: item.status,
				menu_offers: item.offers,
			});
		}

		setIsLoading(false);
		setData(tempBlogs);

		var temppageArray = [];
		var toPage = 0;
		var frompage = currentPage;
		var pageCount =
			fullsnapshot.docs.length > 0
				? Math.ceil(fullsnapshot.docs.length / pageSize)
				: 0; // 16

		// 0 123 -4 Page- 5 6 7 8 9 10 11 12 13 14 15 16 ( rows 160 , Pages 16)

		if (currentPage + 10 < pageCount) {
			toPage = currentPage + 10; // 14 Pages
		} else {
			toPage = pageCount; // 16
		}

		if (currentPage - 10 <= 0) {
			frompage = 1;
		} else {
			frompage = currentPage - 10;
		}

		for (var i = frompage; i <= toPage; i++) {
			temppageArray.push(i);
		}
		setPageArray(temppageArray);
	}
	//Pagination Function End

	return (
		<div>
			<div className="second-menu secondnavlink-menu">
				{/* <a href='/' className='secondnavlink-menu'>
					Home
				</a>
				<a href='/activity' className='secondnavlink-menu'>
					Activity
				</a>
				<a href='/resort' className='secondnavlink-menu'>
					The Resort
				</a>
				<a href='/accomadation' className='secondnavlink-menu'>
					Accomadation
				</a>
				<a href='/environment' className='secondnavlink-menu'>
					Environment Policies
				</a>
				<a href='/ayurweda' className='secondnavlink-menu'>
					Ayurveda
				</a>
				<a href='/wedding' className='secondnavlink-menu'>
					Weddings
				</a>
				<a href='/reservation' className='secondnavlink-menu'>
					Reservations
				</a> */}
				{data &&
					data.map(
						(menu, index) =>
							menu.menu_order == 1 &&
							menu.menu_offers &&
							menu.menu_offers.map((item, index) => (
								<a
									key={index}
									className="secondnavlink-menu"
									href={`${item.url}`}
								>
									{item.lable}
								</a>
							))
					)}
			</div>
		</div>
	);
}
